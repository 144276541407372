<template>
  <div class="container-fluid" id="sy-contenido">
    <section class="accesos text-center">
      <div class="container" id="formulario">
        <div
          class="row d-flex justify-content-between justify-content-md-around align-items-baseline"
        >
          <div id="url"></div>

          <h2>Certificado de Proveedor</h2>
          <!-- formulario-->
          <div class="container-fluid">
            <div class="row mb-4">
              <div class="col-lg-12" id="mensaje"></div>
            </div>
            <div class="row mb-4">
              <div class="col-lg-12">
                <div class="alert alert-info" role="alert">
                  Por favor complete el formulario a continuación.
                </div>
              </div>
            </div>
            <form id="formcp" @submit.prevent="enviarFormulario">
              <div class="row">
                <div class="form-group col-lg-6 col-12">
                  <label>Número de Proveedor:</label>
                  <input
                    class="form-control"
                    v-model="n_destinatario"
                    type="number"
                    data-parsley-required="true"
                    @input="limitarLongitud('n_destinatario', 5)"
                    required
                  />
                </div>
                <div class="form-group col-lg-6 col-12">
                  <label id="dc1">CUIT:</label>
                  <input
                    class="form-control"
                    v-model="cuit"
                    type="number"
                    data-parsley-required="true"
                    @input="limitarLongitud('cuit', 11)"
                    required
                  />
                  <small id="cuitHint" class="form-text text-muted"
                    >Su número de CUIT sin puntos ni espacios</small
                  >
                </div>
              </div>
              <div id="mensaje"></div>
              <button type="submit" class="btn btn-primary btn-block mt-4">
                Obtener Certificado
              </button>
            </form>
          </div>
          <div class="col-lg-12 mt-4">
            <div
              class="alert alert-danger"
              role="alert"
              id="mensajeError"
              v-if="error"
            >
              {{ error }}
            </div>
          </div>

          <!-- fin formulario-->
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12" id="opEx" v-if="certificadoGenerado">
            <div class="row">
              <div class="col-lg-12">
                <div id="contenedorPDF" v-if="!loading">
                  <embed
                    id="pdfo"
                    :src="certificadoURL"
                    type="application/pdf"
                    width="100%"
                    height="600"
                  />
                </div>
                <div id="loader" v-if="loading">
                  <img src="../../assets/img/loading.gif" alt="Loader" />
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div id="mensaje1"></div>
              <div class="col-lg-12 pt-5 justify-content-center">
                <a
                  href="#mensaje"
                  role="button"
                  class="btn btn-primary btn-block"
                  id="btnNueva"
                  @click="generarNuevoCertificado"
                >
                  Generar Nuevo Certificado
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      n_destinatario: "",
      cuit: "",
      certificadoURL: "",
      certificadoGenerado: false,
      error: "",
      loading: false,
    };
  },
  methods: {
    limitarLongitud(propiedad, longitudMaxima) {
      if (this[propiedad].toString().length > longitudMaxima) {
        this[propiedad] = this[propiedad].toString().slice(0, longitudMaxima);
      }
    },
    enviarFormulario() {
      // Validar los datos antes de enviar la solicitud
      if (!this.n_destinatario || !this.cuit) {
        this.error = "Por favor complete todos los campos.";
        return;
      }

      // Realizar la solicitud POST al servicio
      const formData = new FormData();
      this.loading = true;
      formData.append("n_destinatario", this.n_destinatario);
      formData.append("cuit", this.cuit);
      fetch("https://serviciosweb.sanjuan.gob.ar/certificado_proveedor.php", {
        method: "POST",
        body: formData,
      })
      .then((response) => response.json())
      
      .then((data) => {
          if (data.resultado.exito != false) {
            this.certificadoURL = data.datos.urlDescarga;
            this.certificadoGenerado = true;
            this.error = false;
            this.loading = false;
          } else {
            // this.error = "Por favor, verificar los datos ingresados.";
            this.error = data.resultado.msgError;
          }
        })
        .catch((error) => {
          console.error("Error al comunicarse con el servicio:", error);
          this.error = "Error al comunicarse con el servicio. ";
          this.loading = false;
        });
    },
    generarNuevoCertificado() {
      this.n_destinatario = "";
      this.cuit = "";
      this.certificadoURL = "";
      this.certificadoGenerado = false;
      this.error = false;
    },
  },
};
</script>
