<template>
  <div class="" style="min-height: 150px">
    <div style="text-align: justify; overflow-wrap: break-word"
      v-if="!customView && !isDocumentListComponent && !isProveedorComponent">
      <div v-html="html"></div>
    </div>
    <div class="dinamic" v-if="customView">
      <component v-bind:is="customView"></component>
    </div>
    <ProveedorComponent v-if="isProveedorComponent" />
    <ExpedientesComponent v-if="isExpedientesComponent" />

    <DocumentListComponent v-if="isDocumentListComponent" :resultados="5" :show-fecha="false"
      titulo=" Régimen Contrataciones" :data="[
        {
          title: 'Ley N° 2000-A de Contrataciones del Estado Provincial',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/LeyN%C2%BA2000-A_Incluida_la_2030A.PDF',
        },
        {
          title: 'Decreto Reglamentario N° 0004-2020 de la Ley 2000-A',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/DecReg0004Ley2000A.PDF',
        },
        {
          title: 'Decreto Reglamentario N° 0005-2024 de la Ley 2000-A',
          target: '_blank',
          url: 'https://sj.sanjuan.gob.ar/docs/hacienda/compras/dec%20regl%200005_2024.pdf',
        },
        {
          title:
            'Pliego Único de Bases y Condiciones Decreto Nro. 2149-MHyF-22',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Decreto_Nro-2149-MHF-22_Pliego_Unico_de_Bases_y_Condiciones.PDF',
        },
        {
          title: 'ANEXO II EDITABLE 2149',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/ANEXO%20II%20EDITABLE%202149.pdf',
        },
        {
          title:
            'Decreto Nº 0006-2020-Sustituye Art.del DR Nº 0004-2020-A. Procedimiento',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Decreto%20N%C2%BA%200006-2020-Sustituye%20Art.del%20DR%20N%C2%BA%200004-2020-A%20(1).PDF',
        },
        {
          title:
            'Resolución 0001-OCC-2020. Validez certificado de inscripción como proveedor (Abrogada)',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Resolucion_001-20.pdf',
        },
        {
          title:
            'Resolución 038-OCC-2021 - Validez del Certificado de Inscripción como proveedor. Vigencia.',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Resolucion_038-OCC-2021.pdf',
        },
        {
          title:
            'Resolución N° 034-OCC-2022. Aprobación formularios RUPE de alta/modificación persona humana y persona jurídica.',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Rosolucion_034-OCC-2022.pdf',
        },
        {
          title:
            'Resolución N° 060-OCC-2022. Prórroga para actualización de mandato vencido de autoridades de personas jurídicas inscriptas en el RUPE.',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Resolucion_060-OCC-2022.pdf',
        },
        {
          title:
            'Resolución 118-OCC-2022.-Sustituye el art.3 de la Resolución 038/21.Vigencia del Certificado de Proveedor.',
          target: '_blank',
          url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Resolucion_118-OCC-2022.pdf',
        },
      ]" />
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6">
          <DocumentListComponent v-if="isDocumentListComponent" resultados="2" :show-fecha="false"
            titulo=" Régimen de Adecuación de Precios" :data="[
              {
                title: 'Ley 1920-A de Adecuación de Precios',
                target: '_blank',
                url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/4-_Ley_1920-A_de_Adecuaci%C3%B3n_de_Precios.PDF',
              },
              {
                title: 'Decreto Reglamentario N° 0013-2019',
                target: '_blank',
                url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/5-_Decreto_Reglamentario_N%C2%B0_0013-2019.PDF',
              },
              {
                title: 'Resolución N° 133-OCC-2021 Aprobación de Formularios',
                target: '_blank',
                url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Resolucion_N%C2%BA_133-OCC-2021_Aprobacion_de_Formularios_Adecuacion_de_Precios.pdf',
              },
              {
                title: 'Decreto Reglamentario N° 003-2023 de la Ley N° 1920-A',
                target: '_blank',
                url: 'http://sj.sanjuan.gob.ar/docs/hacienda/compras/Dcto.%20Regl.%20N%C2%B0%200003.%20Ley%20N%C2%B0%201920-A%20(1).PDF',
              },
            ]" />
        </div>
        <div class="col-12 col-sm-6">
          <CardGridComponent v-if="isDocumentListComponent" columnas="1" titulo="Circulares OCC" :data="[
            {
              target: '_self',
              title: 'Ver Circulares OCC',
              url: 'https://digesto.sanjuan.gob.ar/digesto/listadoCircularesOCC.php',
            },
          ]" />
        </div>
      </div>
    </div>
    <GridLinkComponent v-if="isDocumentListComponent" titulo="Links Útiles" columnas="1" :data="[
      {
        target: '_self',
        title: 'Digesto Administrativo Provincial',
        url: 'https://digesto.sanjuan.gob.ar',
      },
    ]" />
    <div class="text-center pt-4">
      <p>
        <button v-on:click="back" class="btn btn-secondary text-uppercase">
          Volver
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import ProveedorComponent from "./components/resources/ProveedorComponent.vue";
import ExpedientesComponent from "./components/resources/ExpedientesComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";

export default {
  name: "InfoView",
  props: {
    uid: String,
    filtros: Object,
  },
  data() {
    return {
      html: null,
      customView: false,
      isDocumentListComponent: false,
      isProveedorComponent: false,
      isExpedientesComponent: false,
    };
  },
  methods: {
    back() {
      history.back();
    },
  },
  mounted() {
    if (this.uid === "CUMV-VIEW-ProveedorComponent") {
      this.isProveedorComponent = true;
    } else if (this.uid === "CUMV-VIEW-DocumentListComponent") {
      this.isDocumentListComponent = true;
    } else if (this.uid === "CUMV-VIEW-ExpedientesComponent") {
      this.isExpedientesComponent = true;
    } else if (this.uid.includes("CUMV-"))
      switch (this.uid) {
        case "CUMV-RUPE": {
          this.html = `<!-- SECCION RUPE -->
  <section class="bg-light">
    <div class="container">
      <h2>RUPE</h2>
      <h4>Registro Único de Proveedores del Estado</h4>
      <p>
        Es un instrumento del sistema de compras y contrataciones cuya
        dependencia funcional es en la Oficina Central de Contrataciones. Tiene
        por finalidad registrar a las personas humanas y jurídicas que deseen
        contratar con el Sector Público Provincial No Financiero, mediante la
        exigencia de requisitos de capacidad, solvencia, idoneidad y
        responsabilidad.
      </p>
      <!-- <ul>
        <li>Responsable: CP Gloria Posse</li>
        <li>Teléfono: 264 - 430 6830/66</li>
        <li>Correo: rupesj.proveedores@sanjuan.gob.ar</li>
      </ul>-->
    </div>
  </section>
  <section columnas="3"><div class="container"><h2 class="pb-2"></h2><!--v-if--><div class="row row-cols-2 row-cols-md-6 g-4 justify-content-center text-center"><div class="col"><div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><svg class="ptn-i-2x" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg><!--v-if--></span><div class="card-body"><h3 class="my-0 h6"><a href="https://certificadoprovisorio.sanjuan.gob.ar/" target="_self" class="stretched-link">Certificado Pre-Inscripción como Proveedor</a></h3></div></div></div><div class="col"><div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><svg class="ptn-i-2x" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg><!--v-if--></span><div class="card-body"><h3 class="my-0 h6"><a href="?uid=CUMV-VIEW-ProveedorComponent" target="_self" class="stretched-link">Certificado de Proveedor</a></h3></div></div></div><div class="col"><div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><svg class="ptn-i-2x" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg><!--v-if--></span><div class="card-body"><h3 class="my-0 h6"><a href="?uid=CUMV-COMPRE-SANJUANINO" target="_self" class="stretched-link">Régimen Compre Sanjuaninos</a></h3></div></div></div><div class="col"><div class="card"><span class="card-img-top p-3 text-bg-ptn-color-gsj-brand text-white"><!--v-if--><span class="material-symbols-outlined text-white ptn-i-2x">calendar_month</span></span><div class="card-body"><h3 class="my-0 h6"><a href="https://turnos.sanjuan.gob.ar/" target="_self" class="stretched-link">Turnos Online</a></h3></div></div></div></div></div></section>

  <section>
    <div class="container">
      <h2 class="pb-2">Quiero ser proveedor</h2>
      <a href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/Inscripcion_RUPE_instructivo-convertido-Tramite_Presencial.pdf" target="_blank"class="text-ptn-color-gsj-brand"><h5>Trámite Presencial - Acceder al instructivo</h5></a>
      <h5 class="text-ptn-color-gsj-brand"> <a href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/DOCUMENTO_INSTRUCTIVO_PARA_TRAMITE_NO_PRESENCIAL.pdf" target="_blank"class="text-ptn-color-gsj-brand"> Trámite No Presencial - Acceder al instructivo </a></h5>
    </div>
  </section>
  <section><div class="container "><h2 class="pb-4"> Formularios de Inscripción:</h2><div class="row justify-content-center row-cols-1 g-4 "><div class="col "><div class="card"><div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"><div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"><svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg><!--v-if--></div><!--v-if--><h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/01-alta_proveedor_persona_humana_4.0.pdf" target="_blank" class="stretched-link">Formulario de Inscripción Persona Humana</a></h3></div></div></div><div class="col"><div class="card"><div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"><div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"><svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg><!--v-if--></div><!--v-if--><h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a href="https://compraspublicas.sanjuan.gob.ar/pdf/03-alta_proveedor_persona_juridicas_4.0_Digital%20(1).pdf" target="_blank" class="stretched-link">Formulario de Inscripción Persona Jurídica</a></h3></div></div></div><div class="col"><div class="card"><div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"><div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"><svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg><!--v-if--></div><!--v-if--><h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/02-modificacion_proveedor_persona_humana_4.0.pdf" target="_blank" class="stretched-link">Formulario de Modificación Proveedor Persona Humana</a></h3></div></div></div><div class="col"><div class="card"><div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"><div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"><svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg><!--v-if--></div><!--v-if--><h3 class="card-title m-md-0 px-md-4 h6 text-uppercase"><a href="https://compraspublicas.sanjuan.gob.ar/pdf/04-modificacion_proveedor_persona_juridica_4.1_Digital.pdf" target="_blank" class="stretched-link">Formulario de Modificación Proveedor Persona Jurídica</a></h3></div></div></div>
</section>

  <section>
    <div class="container">
    <h4>TRÁMITE ALTA O MODIFICACIÓN RUBRO COMERCIAL</h4>
      <p>
        El rubro a dar de alta debe figurar en la constancia de AFIP, inscripción sobre los Ingresos Brutos de la <b>Dirección General de Rentas de la Provincia de San Juan </b> o en <b> Convenio Multilateral</b> y corresponderse con lo establecido en el <b>Objeto Social</b>, en el caso de personas jurídicas.
      </p>
      <h4 class="pb-2">Paso a paso:</h4>
      <p>
        <b class="text-ptn-color-gsj-brand">1-</b> Para el alta o la
        modificación del rubro, podes solicitarla a través de correo electrónico
        indicando en el cuerpo del mail los siguientes datos:
      </p>
      <ul>
        <li>
          En Asunto del mail indicar lo que solicita: Alta o Modificación de
          rubro
        </li>
        <li>Nombre y apellido o razón social del proveedor.</li>
        <li>CUIT.</li>
        <li>
          Colocar ALTA/MODIFICACION de rubro, según lo que realice, indicando el
          código de actividad según nomenclador de AFIP y la actividad, ejemplo:
          <br />
          Alta - Activ. Cod. 7.09.00 - Servicios Profesionales.
        </li>
      </ul>
      <p>
        <b class="text-ptn-color-gsj-brand">2-</b> Adjuntar en archivo pdf, la
        documentación de respaldo según corresponda: constancia de AFIP,
        Constancia de DGR y objeto social.
      </p>
      <p>
        <b class="text-ptn-color-gsj-brand">3-</b> Una vez detallado los datos
        solicitados enviar mail al RUPE rupesj.proveedores@sanjuan.gob.ar y a la
        brevedad se le contestara confirmando el alta o modificación.
      </p>
    </div>
    <div class="container ">
    <div class="row justify-content-center row-cols-1 g-4 ">
    <div class="col ">
    <div class="card">
    <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
    <div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
    <svg class="ptn-i-documento text-ptn-color-gsj-2" viewBox="0 0 512 512">
      <path d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"></path>
    </svg>
    </div>
    <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
    <a href="mailto:rupesj.proveedores@sanjuan.gob.ar" target="_blank" class="stretched-link">MODIFICACIÓN O ALTA DE RUBRO COMERCIAL</a>
    </h3>
    </div>
    </div>
    </div>
  </section>
  `;
          break;
        }
        case "CUMV-COMPRE-SANJUANINO": {
          this.html = `  <!--SECCION COMPRE SANJUANINO-->
  <section>
    <div class="container">
      <h2>Régimen Compre Sanjuanino Ley 2135-A</h2>
      <p>
        A través de la realización del Acuerdo San Juan se crea en la Provincia
        el nuevo Régimen de Compre Sanjuanino para los proveedores locales, el
        cual es regulado y aplicado mediante de la sanción de la Ley 2135-A y su
        decreto reglamentario. Este régimen les permite a los proveedores
        locales gozar de los beneficios de preferencia dispuestos en dicha ley,
        en las compras y contrataciones que realice el estado provincial. Se
        aplica a toda la Adminitración Pública Provincial, sus organismos
        descentralizados y autárquicos, cualquiera sea el sistema de adquisición
        o contratación que se utilice. Y según lo dispone la ley 2000-A, en su
        artículo 6°, inciso 10, compete a la Oficina Central de Contrataciones
        organizar, mantener y actualizar el Registro Único de Proveedores del
        Estado Provincial, incluyendo en él, el Registro de Beneficiarios
        Especiales (Compre Sanjuanino). Es por ello que se dispone, a través de
        la Resolución N° 039-OCC-2021, los requisitos y procedimientos para
        solicitar la inscripción o renovación del Beneficio del Compre
        Sanjuanino.
      </p>
    </div>

    <div class="container">
      <h2 class="pb-4">Formularios de Inscripción:</h2>
      <div class="row row-cols-1 g-4">
        <div class="col">
          <div class="card">
            <div
              class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"
            >
              <div
                class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"
              >
                <svg
                  class="ptn-i-documento text-ptn-color-gsj-2"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"
                  ></path>
                </svg>
              </div>

              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                <a
                  href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/Formulario_Compre_Sanjuanino.pdf"
                  target="_blank"
                  class="stretched-link"
                  >Formulario para solicitar el COMPRE SANJUANINO</a
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div
              class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"
            >
              <div
                class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"
              >
                <svg
                  class="ptn-i-documento text-ptn-color-gsj-2"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"
                  ></path>
                </svg>
              </div>
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                <a
                  href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/ley_compre_2135-A.pdf"
                  target="_blank"
                  class="stretched-link"
                  >Ley 2135-A. RÉGIMEN COMPRE SANJUANINO</a
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div
              class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"
            >
              <div
                class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"
              >
                <svg
                  class="ptn-i-documento text-ptn-color-gsj-2"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"
                  ></path>
                </svg>
              </div>

              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                <a
                  href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/Decreto_Reglamentario_Regimen_Compre_Sanjuanino_N%C2%B0_0396-2021.pdf"
                  target="_blank"
                  class="stretched-link"
                  >Decreto Reglamentario N° 0396-2021</a
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div
              class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"
            >
              <div
                class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"
              >
                <svg
                  class="ptn-i-documento text-ptn-color-gsj-2"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"
                  ></path>
                </svg>
              </div>

              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                <a
                  href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/Resolucion_N%C2%B0_039-OCC-2021.pdf"
                  target="_blank"
                  class="stretched-link"
                  >Resolución N° 039-OCC-2021. Requisitos para solicitar o
                  renovar el beneficio del Compre Sanjuanino</a
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div
              class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column"
            >
              <div
                class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3"
              >
                <svg
                  class="ptn-i-documento text-ptn-color-gsj-2"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="m170 379l172 0 0-32-172 0z m0-91l172 0 0-32-172 0z m-53 181c-8 0-16-3-22-9-6-7-10-14-10-23l0-362c0-9 4-16 10-23 6-6 14-9 22-9l193 0 117 116 0 278c0 9-4 16-10 23-6 6-14 9-22 9z m177-295l0-99-177 0 0 362 278 0 0-263z m-177-99l0 99 0-99 0 362z"
                  ></path>
                </svg>
              </div>
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                <a
                  href="https://sj.sanjuan.gob.ar/docs/hacienda/compras/Circular_N%C2%BA_16-OCC-2021_Calculo_Compre_Sanjuanino.pdf"
                  target="_blank"
                  class="stretched-link"
                  >Circular N° 016-OCC-2021. Aplicación del beneficio del
                  Régimen de Compre Sanjuanino. Cálculo numérico.</a
                >
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="container"><h2 class="pb-4"></h2><div class="row d-flex"><div class="mb-3 col-lg-3"><h3 class="px-4 py-2 rounded-top fs-5 text-bg-ptn-color-gsj-brand">Contacto</h3><ul class="list-unstyled px-4"><li class="mb-2"><a class="fac-a" href="mailto:compresanjuanino@sanjuan.gob.ar" target="_blank"><span class="material-symbols-outlined fac-span">mail</span><!----><!----> compresanjuanino@sanjuan.gob.ar</a><!----></li><li class="mb-2"><b class="fac-a"><span class="material-symbols-outlined fac-span ">schedule</span><!----><!----> Duración del trámite: entre 3 y 5 días hábiles.
</b><!----></li></ul></div></div></div>
  <!--FIN SECCION COMPRE SANJUANINO-->`;
        }
      }
    else {
      let params = {};
      if (this.filtros != null && this.filtros != "") {
        params = this.filtros;
      }
      params.uid = this.uid;

      axios
        .post(process.env.VUE_APP_API_URL, params)
        .then((response) => {
          this.html = response.data ? response.data.html : null;
          localStorage.setItem(this.uid, JSON.stringify(response));
        })
        .catch((error) => {
          if (!error.response) {
            console.log("Fuera de Linea");
            if (localStorage.getItem(this.uid)) {
              console.log("Cargando elementos de cache para " + this.uid);
              var resp = JSON.parse(localStorage.getItem(this.uid));
              this.html = resp.data ? resp.data.html : null;
            }
          }
        });
    }
  },
  components: {
    DocumentListComponent,
    ProveedorComponent,
    ExpedientesComponent,
    GridLinkComponent,
    CardGridComponent,
  },
};
</script>
