<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
  },
  data() {
    return {
      site: 29,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
  <!--SECCION  CARD -->

  <ITCGridComponent
    :columnas="3"
    :data="[
      {
        icon: 'article',
        target: null,
        title: 'Normativa Vigente',
        url: '?uid=CUMV-VIEW-DocumentListComponent',
      },
      {
        icon: 'assignment',
        target: null,
        title: 'RUPE',
        url: '?uid=CUMV-RUPE',
      },
      {
        icon: 'inventory',
        target: null,
        title: 'Próximas Aperturas',
        url: 'https://licitaciones.sanjuan.gob.ar/',
      },
      {
        icon: 'tips_and_updates',
        target: null,
        title: 'Sugerencias',
        url: 'https://docs.google.com/forms/d/e/1FAIpQLScnQ9s7hjKlpcfx80pMRjpyXWA_JFF4U-XJDLJnR_G1DQcltQ/viewform',
      },
      /*  {
        icon: 'help',
        target: null,
        title: 'Ayuda',
        url: null,
      }, */
      {
        icon: 'quick_reference_all',
        target: null,
        title: 'Consulta Expedientes',
        url: '?uid=CUMV-VIEW-ExpedientesComponent',
      },
    ]"
  />

  <!--FIN SECCION CARD -->

  <!-- SECCION INSTITUCIONAL-->

  <section id="institucional" class="bg-light">
    <div class="container">
      <h2 class="pb-4">Institucional</h2>
      <p>
        La Dirección General de Compras y Contrataciones en su carácter de
        Órgano Rector del Sistema de Contrataciones de la Provincia de San Juan,
        tiene el compromiso primordial de organizar las políticas de
        adquisiciones en el ámbito de la provincia, coordinando las propuestas
        elevadas por cada Jurisdicción o Entidad respecto de las Unidades
        Operativas de Compras que se desarrollarán en el ámbito de sus
        respectivas jurisdicciones. A tales fines, es el receptor de sus
        respectivos Planes Anuales de Compras y Contrataciones. <br />
        <br />
        Entre sus funciones como Órgano Rector se encuentran, entre otras, las
        de sugerir políticas de contrataciones, proponer el dictado de normativa
        reglamentaria en la materia, administrar el sistema de información,
        proporcionar los precios de referencia, catalogación, supervisar y
        evaluar el diseño y operatividad del sistema de contrataciones y aplicar
        sanciones a oferentes y adjudicatarios en caso de corresponder.
      </p>
    </div>
  </section>

  <!-- FIN SECCION INSTITUCIONAL-->

  <!-- SECCION NOVEDADES-->

  <!-- BOTON FORMULARIO DE INSCRIPCION -->

  <!--   <section class="container mb-0 pb-0">
    <h2 class="pb-4">Novedades</h2>
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-warning fade show p-4">
          <div class="row">
            <div class="col-lg-6">
              <b class="text-ptn-color-gsj-2">
                RESOLUCION N° 129-OCC-2023 RUPE: Nuevo Procedimiento de
                Inscripción o Modificación de Proveedores</b
              >
            </div>
            <div class="col-lg-6">
              <a
                href="https://sorteovivienda.sanjuan.gob.ar/sorteo_provincial_2023/gh/portalIPV/Res_129-OCC-2023_RUPE_NuevoProcedimientoInscripcionModificacionProveedores.pdf"
                class="btn btn-secondary text-bg-ptn-color-gsj-brand"
                style="width: 100%"
                target="_blank"
                >Ver resolución</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <!-- FIN SECCION NOVEDADES-->

  <!-- SECCION CONTACTO-->

  <section id="contacto">
    <div class="container">
      <h2 class="pb-4">Contacto</h2>
      <div class="row d-flex justify-content-center">
        <div class="mb-3 col-lg-3">
          <h3 class="px-4 py-2 rounded-top fs-5 text-bg-ptn-color-gsj-brand">
            Operaciones
          </h3>
          <ul class="list-unstyled px-4">
            <li class="mb-2">
              <a
                class="fac-a"
                href="https://goo.gl/maps/dgYfg3pdTVXzkGdAA"
                target="_blank"
                ><!--v-if--><!--v-if--><span
                  class="material-symbols-outlined fac-span"
                  >public</span
                >
                Centro Cívico 1 Piso entre Núcleo 5 y 6</a
              ><!--v-if-->
            </li>
            <li class="mb-2">
              <a
                class="fac-a"
                href="mailto:oc.contrataciones@sanjuan.gov.ar"
                target="_blank"
                ><!--v-if--><span class="material-symbols-outlined fac-span"
                  >mail</span
                ><!--v-if-->
                oc.contrataciones@sanjuan.gov.ar</a
              ><!--v-if-->
            </li>
            <li class="mb-2">
              <a class="fac-a" href="tel:2644303372" target="_blank"
                ><span class="material-symbols-outlined fac-span">call</span
                ><!--v-if--><!--v-if-->
                (264) 4303372 / 4306840 / 4306841</a
              ><!--v-if-->
            </li>
          </ul>
        </div>
        <div class="mb-3 col-lg-3">
          <h3 class="px-4 py-2 rounded-top fs-5 text-bg-ptn-color-gsj-2">
            RUPE
          </h3>
          <ul class="list-unstyled px-4">
            <li class="mb-2">
              <a
                class="fac-a"
                href="mailto: rupesj.proveedores@sanjuan.gov.ar"
                target="_blank"
                ><!--v-if--><span class="material-symbols-outlined fac-span"
                  >mail</span
                ><!--v-if-->
                rupesj.proveedores@sanjuan.gov.ar</a
              ><!--v-if-->
            </li>
            <li class="mb-2">
              <a class="fac-a" href="tel: 264430683066" target="_blank"
                ><span class="material-symbols-outlined fac-span">call</span
                ><!--v-if--><!--v-if-->
                (0264) 430-6830/66</a
              ><!--v-if-->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <!-- FIN SECCION CONTACTO-->

  <GridLinkComponent
    titulo="Links Útiles"
    :data="[
      {
        title: 'Digesto Administrativo',
        subtitle: 'Página Web',
        url: 'https://digesto.sanjuan.gob.ar/digesto/',
      },
      {
        title: 'Régimen Federal de Responsabilidad Fiscal',
        subtitle: 'Página Web',
        url: 'https://web.sanjuan.gob.ar/hacienda/',
      },

      {
        title: 'Consulta Comprobante de Pago y Retenciones',
        subtitle: 'Página Web',
        url: 'https://consultapagosyretenciones.sanjuan.gob.ar/',
      },
      {
        title: 'Resultado de Exámenes',
        subtitle: 'Página Web',
        url: 'https://minhacienda.sanjuan.gob.ar/resultados_examenes.html',
      },

      {
        title: 'Plan Estratégico San Juan',
        subtitle: 'Página Web',
        url: 'https://planestrategico.sanjuan.gob.ar/',
      },
      {
        title: 'Instituto de Investigaciones Económicas y Estadísticas',
        subtitle: 'Página Web',
        url: 'https://web.sanjuan.gob.ar/iiee/',
      },
    ]"
  />
</template>
